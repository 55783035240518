import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { getCountryCode } from '../utils/utils';

const GTM = [
  {
    country: '*',
    gtmId: 'GTM-TTL3FBMJ',
  },
  {
    country: 'tr',
    gtmId: 'GTM-N363K6S',
  },
  {
    country: 'hu',
    gtmId: 'GTM-KSGRKF4',
  },
  {
    country: 'cz',
    gtmId: 'GTM-5C5PFNF',
  },
  {
    country: 'sk',
    gtmId: 'GTM-NVSPBD4',
  },
  {
    country: 'at',
    gtmId: 'GTM-K2FNKH2',
  },
  {
    country: 'id',
    gtmId: 'GTM-MRS4FZN',
  },
  {
    country: 'it',
    gtmId: 'GTM-58TF7SV',
  },
  {
    country: 'es',
    gtmId: 'GTM-WPMTJTW',
  },
  {
    country: 'my',
    gtmId: 'GTM-P9N4R36',
  },
  {
    country: 'ph',
    gtmId: 'GTM-5WXV7K2',
  },
  {
    country: 'au',
    gtmId: 'GTM-MJM5MQVH',
  },
  {
    country: 'nz',
    gtmId: 'GTM-N7R645MQ',
  },
  {
    country: 'eg',
    gtmId: 'GTM-MZSNTS6G',
  },
  {
    country: 'pl',
    gtmId: 'GTM-5F8L7V6H',
  },
  {
    country: 'sg',
    gtmId: 'GTM-N7W936SM',
  },
  {
    country: 'be',
    gtmId: 'GTM-5PWG8FJD',
  },
  {
    country: 'nl',
    gtmId: 'GTM-TGQJRH4J',
  },
  {
    country: 'us',
    gtmId: 'GTM-NSJDQM9G',
  },
  {
    country: 'de',
    gtmId: 'GTM-PTW5N5ZN',
  },
  {
    country: 'rs',
    gtmId: 'GTM-T8CHSB76',
  },
  {
    country: 'za',
    gtmId: 'GTM-T6KLBG4T',
  },
  {
    country: 'pt',
    gtmId: 'GTM-P63TXMD9',
  },
  {
    country: 'ua',
    gtmId: 'GTM-WX66JQLB',
  }
];

const GTM_Elektrabregenz = [
  {
    country: '*',
    gtmId: 'GTM-TCWGH2XC',
  },
  {
    country: 'at',
    gtmId: 'GTM-5PSZ37NQ',
  }
];

export const useTagManager = (brandName) => {
  const countryCode = getCountryCode();
  useEffect(() => {
    let gtm;
    if (brandName == "beko") {
      if (countryCode) {
        gtm = GTM.find((x) => x.country == countryCode);
      } else {
        gtm = GTM.find((x) => x.country === '*');
      }
    } else if (brandName = "elektrabregenz") {
      if (countryCode) {
        gtm = GTM_Elektrabregenz.find((x) => x.country == countryCode);
      } else {
        gtm = GTM_Elektrabregenz.find((x) => x.country === '*');
      }
    } else return;

    if (!gtm) {
      return;
    }
    const tagManagerArgs = {
      gtmId: gtm.gtmId,
    };

    TagManager.initialize(tagManagerArgs);
  }, [countryCode]);

  return TagManager;
};
